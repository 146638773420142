import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42')
];

export const server_loads = [0];

export const dictionary = {
		"/[[lang=lang]]/about-us": [6],
		"/[[lang=lang]]/account": [7],
		"/[[lang=lang]]/account/activate": [8],
		"/[[lang=lang]]/account/address/add": [9],
		"/[[lang=lang]]/account/address/edit/[slug]": [10],
		"/[[lang=lang]]/account/address/list": [11],
		"/[[lang=lang]]/account/circuit-master": [12],
		"/[[lang=lang]]/account/circuit-master/login": [13],
		"/[[lang=lang]]/account/forgotten-password": [14],
		"/[[lang=lang]]/account/login": [15],
		"/[[lang=lang]]/account/order/[slug]": [16],
		"/[[lang=lang]]/account/register": [17],
		"/[[lang=lang]]/account/reset": [18],
		"/[[lang=lang]]/bag": [19],
		"/[[lang=lang]]/brand-responsibility": [20],
		"/[[lang=lang]]/customer-service/contact-us": [21],
		"/[[lang=lang]]/customer-service/contact-us/email-us": [22],
		"/[[lang=lang]]/customer-service/delivery": [23],
		"/[[lang=lang]]/customer-service/faqs": [24],
		"/[[lang=lang]]/customer-service/gift-cards": [25],
		"/[[lang=lang]]/customer-service/our-stores": [26],
		"/[[lang=lang]]/customer-service/product-care": [27],
		"/[[lang=lang]]/customer-service/returns": [28],
		"/[[lang=lang]]/customer-service/size-guide": [29],
		"/[[lang=lang]]/customer-service/stockists": [30],
		"/[[lang=lang]]/legal/cookie-policy": [31],
		"/[[lang=lang]]/legal/declaration-of-conformity": [32],
		"/[[lang=lang]]/legal/klarna": [33],
		"/[[lang=lang]]/legal/modern-slavery-statement": [34],
		"/[[lang=lang]]/legal/privacy-policy": [35],
		"/[[lang=lang]]/legal/terms-and-conditions": [36],
		"/[[lang=lang]]/preview/nav/[navId]/[revisionId]": [~37],
		"/[[lang=lang]]/preview/page/[pageId]/[revisionId]": [~38],
		"/[[lang=lang]]/recommendations": [39],
		"/[[lang=lang]]/search": [40],
		"/[[lang=lang]]/signup": [41],
		"/[[lang=lang]]/social-responsibility": [42],
		"/[[lang=lang]]": [2],
		"/[[lang=lang]]/[landing]": [3],
		"/[[lang=lang]]/[landing]/[collection]": [4],
		"/[[lang=lang]]/[landing]/[collection]/[slug]": [5]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';